import React, { useState, useEffect } from 'react';
import { Alert, AlertDescription } from '../ui/alert';
import { Button } from '../ui/button';
import { Input } from '../ui/input';
import { Textarea } from '../ui/textarea';
import { Label } from '../ui/label';
import { Loader2, PaperclipIcon, RefreshCw } from 'lucide-react';

const ZohoSupportForm = () => {
  const [formData, setFormData] = useState({
    'First Name': '',
    'Contact Name': '',
    'Email': '',
    'Phone': '',
    'Subject': '',
    'Description': '',
    'zsWebFormCaptchaWord': '',
  });
  const [attachments, setAttachments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [captchaUrl, setCaptchaUrl] = useState('');
  const [captchaDigest, setCaptchaDigest] = useState('');

  useEffect(() => {
    regenerateCaptcha();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    if (attachments.length + files.length > 5) {
      alert('Você pode anexar um máximo de 5 arquivos.');
      return;
    }
    setAttachments(prev => [...prev, ...files]);
  };

  const removeAttachment = (index) => {
    setAttachments(prev => prev.filter((_, i) => i !== index));
  };

  const regenerateCaptcha = async () => {
    try {
      const response = await fetch('https://desk.zoho.com/support/GenerateCaptcha?action=getNewCaptcha&_=' + new Date().getTime());
      const data = await response.json();
      setCaptchaUrl(data.captchaUrl);
      setCaptchaDigest(data.captchaDigest);
    } catch (error) {
      console.error('Failed to generate captcha:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const form = new FormData();
    for (const key in formData) {
      form.append(key, formData[key]);
    }
    form.append('xnQsjsdp', 'edbsn74c5996978d5cfcc8f02d09df0d2ac3d');
    form.append('xmIwtLD', 'edbsn87351b7704c473f22bc2638a5449cffee4d107dcfd6674b9dbe4723a60956184');
    form.append('xJdfEaS', captchaDigest);
    form.append('actionType', 'Q2FzZXM=');
    form.append('returnURL', 'https://obrigado.abla.one');

    attachments.forEach((file, index) => {
      form.append(`attachment_${index + 1}`, file);
    });

    try {
      const response = await fetch('https://desk.zoho.com/support/WebToCase', {
        method: 'POST',
        body: form
      });

      if (response.ok) {
        setShowSuccess(true);
        setFormData({
          'First Name': '',
          'Contact Name': '',
          'Email': '',
          'Phone': '',
          'Subject': '',
          'Description': '',
          'zsWebFormCaptchaWord': '',
        });
        setAttachments([]);
      } else {
        alert('Ocorreu um erro ao enviar o formulário. Por favor, tente novamente.');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      alert('Ocorreu um erro ao enviar o formulário. Por favor, tente novamente.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="max-w-2xl mx-auto p-6 bg-white rounded-lg shadow-md">
      <h2 className="text-2xl font-bold mb-6">Checklist - Suporte MM</h2>
      {showSuccess && (
        <Alert className="mb-6">
          <AlertDescription>
            Seu formulário foi enviado com sucesso!
          </AlertDescription>
        </Alert>
      )}
      <form onSubmit={handleSubmit}>
        <div className="space-y-4">
          <div>
            <Label htmlFor="First Name">Nome</Label>
            <Input
              id="First Name"
              name="First Name"
              value={formData['First Name']}
              onChange={handleInputChange}
              required
            />
          </div>
          <div>
            <Label htmlFor="Contact Name">Sobrenome</Label>
            <Input
              id="Contact Name"
              name="Contact Name"
              value={formData['Contact Name']}
              onChange={handleInputChange}
              required
            />
          </div>
          <div>
            <Label htmlFor="Email">E-mail</Label>
            <Input
              id="Email"
              name="Email"
              type="email"
              value={formData['Email']}
              onChange={handleInputChange}
              required
            />
          </div>
          <div>
            <Label htmlFor="Phone">Telefone</Label>
            <Input
              id="Phone"
              name="Phone"
              value={formData['Phone']}
              onChange={handleInputChange}
              required
            />
          </div>
          <div>
            <Label htmlFor="Subject">Nome do Problema</Label>
            <Input
              id="Subject"
              name="Subject"
              value={formData['Subject']}
              onChange={handleInputChange}
              required
            />
          </div>
          <div>
            <Label htmlFor="Description">Descrição do Problema</Label>
            <Textarea
              id="Description"
              name="Description"
              value={formData['Description']}
              onChange={handleInputChange}
              required
            />
          </div>
          <div>
            <Label htmlFor="file">Anexo</Label>
            <div className="flex items-center space-x-2">
              <Input
                id="file"
                type="file"
                onChange={handleFileChange}
                className="hidden"
                multiple
              />
              <Button type="button" variant="outline" onClick={() => document.getElementById('file').click()}>
                <PaperclipIcon className="mr-2 h-4 w-4" /> Anexar arquivos
              </Button>
            </div>
            <p className="text-sm text-gray-500 mt-1">Cada arquivo pode ter até 20MB de tamanho.</p>
            {attachments.length > 0 && (
              <ul className="mt-2 space-y-1">
                {attachments.map((file, index) => (
                  <li key={index} className="flex items-center justify-between text-sm">
                    <span>{file.name}</span>
                    <Button type="button" variant="ghost" size="sm" onClick={() => removeAttachment(index)}>
                      X
                    </Button>
                  </li>
                ))}
              </ul>
            )}
          </div>
          <div>
            <Label htmlFor="zsWebFormCaptchaWord">Captcha</Label>
            <div className="flex items-center space-x-2">
              <img src={captchaUrl} alt="Captcha" className="border" />
              <Button type="button" variant="outline" size="icon" onClick={regenerateCaptcha}>
                <RefreshCw className="h-4 w-4" />
              </Button>
            </div>
            <Input
              id="zsWebFormCaptchaWord"
              name="zsWebFormCaptchaWord"
              value={formData.zsWebFormCaptchaWord}
              onChange={handleInputChange}
              required
              className="mt-2"
            />
          </div>
        </div>
        <div className="mt-6 flex justify-center space-x-4">
          <Button type="submit" disabled={isLoading}>
            {isLoading ? <Loader2 className="mr-2 h-4 w-4 animate-spin" /> : null}
            Enviar
          </Button>
          <Button type="reset" variant="outline" onClick={() => {
            setFormData({
              'First Name': '',
              'Contact Name': '',
              'Email': '',
              'Phone': '',
              'Subject': '',
              'Description': '',
              'zsWebFormCaptchaWord': '',
            });
            setAttachments([]);
          }}>
            Restaurar
          </Button>
        </div>
      </form>
      <div className="mt-6 text-center text-sm text-gray-500">
  desenvolvido por 
    <a href="https://abla.one" target="_blank" rel="noopener noreferrer" className="ml-1 text-blue-500 hover:underline">
    Abla One
      </a>
      </div>
    </div>
  );
};

export default ZohoSupportForm;