import * as React from "react"
import { cn } from "../lib/utils"

const Alert = React.forwardRef(({ className, variant, ...props }, ref) => (
  <div
    ref={ref}
    role="alert"
    className={cn(
      "border-l-4 p-4",
      {
        "bg-blue-100 border-blue-500 text-blue-700": variant === "info",
        "bg-green-100 border-green-500 text-green-700": variant === "success",
        "bg-yellow-100 border-yellow-500 text-yellow-700": variant === "warning",
        "bg-red-100 border-red-500 text-red-700": variant === "error",
      },
      className
    )}
    {...props}
  />
))
Alert.displayName = "Alert"

const AlertDescription = React.forwardRef(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn("text-sm", className)}
    {...props}
  />
))
AlertDescription.displayName = "AlertDescription"

export { Alert, AlertDescription }