import * as React from "react"
import { cn } from "../lib/utils"

const Button = React.forwardRef(({ className, variant, size, ...props }, ref) => {
  return (
    <button
      className={cn(
        "inline-flex items-center justify-center rounded-md text-sm font-medium transition-colors focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none",
        {
          "bg-slate-900 text-white hover:bg-slate-700": variant === "default",
          "bg-transparent border border-slate-200 hover:bg-slate-100": variant === "outline",
          "p-2": size === "sm",
          "px-4 py-2": size === "default",
          "px-6 py-3": size === "lg",
        },
        className
      )}
      ref={ref}
      {...props}
    />
  )
})
Button.displayName = "Button"

export { Button }