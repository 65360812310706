import React from 'react';
import ZohoSupportForm from './components/ZohoSupportForm';

function App() {
  return (
    <div className="App">
      <ZohoSupportForm />
    </div>
  );
}

export default App;